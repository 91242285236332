import {
    GET_DATA_DESCARGAS_ARCHIVOS,
    GET_DATA_DESCARGAS_ARCHIVOS_COPY,
    LOADING_DATA_DESCARGAS_ARCHIVOS,
    GET_DATA_TIPOS_ARCHIVOS_DESCARGAS_ARCHIVOS,
} from "../../../../Constans/Administracion/Administrador/DescargasArchivos"

const INIT_STATE = {
    rex_data_descargas_archivos                   : [],
    rex_data_descargas_archivos_copia             : [],
    rex_data_tipos_archivos_descargas_archivos    : [],
    rex_loading_data_carga_archivos               : true,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_DESCARGAS_ARCHIVOS: {
            return {
                ...state,
                rex_data_descargas_archivos : action.payload
            }
        }
        case GET_DATA_DESCARGAS_ARCHIVOS_COPY: {
            return {
                ...state,
                rex_data_descargas_archivos_copia : action.payload
            }
        }
        case GET_DATA_TIPOS_ARCHIVOS_DESCARGAS_ARCHIVOS: {
            return {
                ...state,
                rex_data_tipos_archivos_descargas_archivos : action.payload
            }
        }
        case LOADING_DATA_DESCARGAS_ARCHIVOS: {
            return {
                ...state,
                rex_loading_data_carga_archivos : action.payload
            }
        }
        default:
            return state;
    }
}