import config from '../../../../config'
import {
    OBTENER_DATA_NO_HOMOLOGADOS,
    OBTENER_DATA_MASTER_PRODUCTOS,
    SELECT_PRODUCT_NO_HOMOLOGACION,
    FILTRO_INPUT_DATA_NO_HOMOLOGADOS,
    FILTRO_ORDEN_DATA_NO_HOMOLOGADOS,
    PAGINA_TABLA_DATA_NO_HOMOLOGADOS,
    FILTRO_TIPOS_CONEXIONES_NO_HOMOLOGADOS
} from '../../../../Constans/Homologaciones/NoHomologados/NoHomologados'
import { notification } from 'antd'
import {
    ObtenerHomologadosReducer
} from '../Homologados/Homologados'
import { CARGANDO_DATA_NO_HOMOLOGADOS, DATA_TOTAL_NO_HOMOLOGADOS } from '../../../../Constans/Homologaciones/NoHomologados/AsignarNoHomologados'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const ObtenerNoHomologadosReducer = (total = false) => async ( dispatch, getState ) => {

    const pag_tabla_no_homologados              = getState().noHomologados.rex_pagina_tabla_data_no_homologados
    const filtro_orden_no_homologados           = getState().noHomologados.rex_filtro_orden_data_no_homologados
    const rex_filtro_input_data_no_homologados  = getState().noHomologados.rex_filtro_input_data_no_homologados
    
    dispatch({
        type    : CARGANDO_DATA_NO_HOMOLOGADOS,
        payload : true
    })
    
    await fetch(config.api_url+'approvals/get-non-approved-products?page='+pag_tabla_no_homologados+'&total='+total,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_cliente_hml  : rex_filtro_input_data_no_homologados?.cliente_hml          ? rex_filtro_input_data_no_homologados.cliente_hml : '',
                req_attr_1       : rex_filtro_input_data_no_homologados?.attr_1             ? rex_filtro_input_data_no_homologados.attr_1 : '',
                req_zone         : rex_filtro_input_data_no_homologados?.zone                 ? rex_filtro_input_data_no_homologados.zone : '',
                req_regional_gba   : rex_filtro_input_data_no_homologados?.regional_gba           ? rex_filtro_input_data_no_homologados.regional_gba : '',
                req_cod_producto : rex_filtro_input_data_no_homologados?.codigo_producto      ? rex_filtro_input_data_no_homologados.codigo_producto : '',
                req_des_producto : rex_filtro_input_data_no_homologados?.descripcion_producto ? rex_filtro_input_data_no_homologados.descripcion_producto : '',
                req_desde        : rex_filtro_input_data_no_homologados?.desde                ? rex_filtro_input_data_no_homologados.desde : '',
                req_ytd          : rex_filtro_input_data_no_homologados?.s_ytd                ? rex_filtro_input_data_no_homologados.s_ytd.replace(',','') : '',
                req_mtd          : rex_filtro_input_data_no_homologados?.s_mtd                ? rex_filtro_input_data_no_homologados.s_mtd.replace(',','') : '',
                req_column       : filtro_orden_no_homologados?.column      ? filtro_orden_no_homologados.column : 's_ytd',
                req_orden        : filtro_orden_no_homologados?.orden       ? filtro_orden_no_homologados.orden : 'desc',
                req_total        : total,
                req_filtro_responsable  : rex_filtro_input_data_no_homologados?.responsable             ? rex_filtro_input_data_no_homologados.responsable : '',
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(total){
            dispatch({
                type: DATA_TOTAL_NO_HOMOLOGADOS,
                payload: data.total_data
            })
        }      
        dispatch({
            type: OBTENER_DATA_NO_HOMOLOGADOS,
            payload: data.data
        })

        let filter_connection = []
        data.filter_connection.map(fil => {
            filter_connection.push({label:fil.attr_1, value : fil.attr_1})
        })
        dispatch({
            type: FILTRO_TIPOS_CONEXIONES_NO_HOMOLOGADOS,
            payload: filter_connection
        })

    }).catch((error)=> {
        console.log(error)
    });    

    dispatch({
        type    : CARGANDO_DATA_NO_HOMOLOGADOS,
        payload : false
    })
}

export const ObtenerMasterProductosReducer = () => async ( dispatch ) => {

    await fetch(config.api_url+'approvals/get-master-products',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_mp_grow : true,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        dispatch({
            type: OBTENER_DATA_MASTER_PRODUCTOS,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })

}

export const RegistrarHomologacionReducer = (producto_so_id, producto_id, envio_otros=false, so_ids=[], uni_medida=null) => async ( dispatch ) => {

    await fetch(config.api_url+'approvals/register-product',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                producto_so_id      : producto_so_id,
                producto_hml_id     : producto_id,
                req_envio_otros     : envio_otros,
                productos_so_ids    : so_ids,
                producto_uni_medida : uni_medida
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.respuesta){
            notificacionServidor('success', data.message)
            dispatch(ObtenerNoHomologadosReducer(true))
            dispatch(ObtenerHomologadosReducer())
        }else{
            if(data.status == 400){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    return true
}

export const SelectProductsNoHomologacionReducer = (value) => async ( dispatch ) => {
    console.log(value);
    await dispatch({
        type: SELECT_PRODUCT_NO_HOMOLOGACION,
        payload: value
    })

    return true
}

export const ActualizarFiltroInputNoHomologados = (column, value) => async ( dispatch, getState ) => {

    let filtro_input = getState().noHomologados.rex_filtro_input_data_no_homologados

    filtro_input = {...filtro_input, [column] : value}

    await dispatch({
        type: FILTRO_INPUT_DATA_NO_HOMOLOGADOS,
        payload: filtro_input
    })

    return true
}

export const ActualizarFiltroOrdenNoHomologados = (column, order) => async ( dispatch ) => {

    const filtro_orden = { column : column, orden: order}

    dispatch({
        type: FILTRO_ORDEN_DATA_NO_HOMOLOGADOS,
        payload: filtro_orden
    })

    let orden_index = column == 'index' ? true : false

    dispatch(ObtenerNoHomologadosReducer(orden_index))

    return true
}

export const ActualizarPaginaTablaNoHomologados = (page) => async ( dispatch ) => {

    dispatch({
        type: PAGINA_TABLA_DATA_NO_HOMOLOGADOS,
        payload: page
    })

    dispatch(ObtenerNoHomologadosReducer(true))

    return true
}