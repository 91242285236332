import React, {useState, useRef, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { Card, Tag, Button, DatePicker, Modal, notification } from 'antd'
import { PlusOutlined, ExclamationOutlined, ExclamationCircleFilled  } from '@ant-design/icons'
import Cohete from '../../Assets/Img/UploadFile/cohete.gif'
import CargandoCaminata from '../../Assets/Img/UploadFile/cargandoCaminata.gif'
import Saltando from '../../Assets/Img/UploadFile/saltando.gif'
import '../../Styles/Components/UploadFile/CardUploadFile.css'

const CardUploadFile = (props) => {
    const { Meta } = Card
    const titleCard = props.titleCard
    const textCard = props.textCard
    const actionCard = props.action
    const extraAction = props.extraAction
    const uploaddatarestriction = props.uploaddatarestriction
    
    const dispatch = useDispatch()
    const [fileNameTag, setFileNameTag] = useState(false)
    const [ showDatePicker, setShowDatePicker ] = useState(false)
    const [fileUrl, setFileUrl] = useState([])
    const [loading, setLoading] = useState(false)
    const [errorFile, setErrorFile] = useState(false)
    const [sendDataFile, setSendDataFile] = useState(false)
    const [ dateDatePicker, setDateDatePicker ] = useState(null)
    const [ disabledDistributor, setDisabledDistributor ] = useState(false)
    const RefFile = useRef(null)

    const imageFileUpload = (image) => {
        if(image){
            setFileUrl([image])
            setFileNameTag(image.name)
        }
    }

    const notificationNoFile = () => {
        notification["warning"]({
            message: "Advertencia",
            description: "Por favor seleccione un archivo",
            duration: 2
        })
    }

    const loadRestriction = (day) => {
        notification["warning"]({
            message: "Advertencia",
            description: "Lo sentimos, no se puede subir información del sell out después del " + day +".",
            duration: 6
        })
    }

    const deleteFileUpload = () => {
        setFileUrl(false)
        setFileNameTag(false)
        setSendDataFile(false)
    }

    const sendFile = async () => {
        if(!fileNameTag && !errorFile){
            notificationNoFile()
            return
        }
        if(!errorFile){
            setSendDataFile(true)
            setLoading(true)
            const rptaCarga = await dispatch(actionCard(fileUrl, false, titleCard, dateDatePicker))
            if(rptaCarga == true){
                setLoading(false)
                setErrorFile(false)
            }else{
                setLoading(false)
                setErrorFile(true)
            }
            setFileNameTag(false)
            setFileUrl([])
        }else{
            setSendDataFile(false)
            setErrorFile(false)
        }
    }

    useEffect(() => {
    },[dateDatePicker])

    const alertDateInvalid = (message) => {
        Modal.info({
          title: '¡Alerta!',
          content: (
            <div>
              <p>{message}</p>
            </div>
          ),
          onOk() {},
          icon: <ExclamationCircleFilled/>
        });
    }

    const validateDate = async (date) => {
        const currentMonth = new Date().getMonth() + 1
        const currentYear = new Date().getFullYear()
        const [ yearSelected, monthSelected ] = date.split('-')
        let message
        let lastMonth = currentMonth - 1;
        let lastYear = currentYear;
        
        if (lastMonth === 0) {
            lastMonth = 12;
            lastYear--;
        }

        if (parseInt(monthSelected) === lastMonth && parseInt(yearSelected) === lastYear || parseInt(monthSelected) === lastMonth +1 && parseInt(yearSelected) === lastYear) {
            RefFile.current.click()
            setDateDatePicker(date)
            return
        } else if (parseInt(yearSelected) < currentYear || (parseInt(yearSelected) === currentYear && parseInt(monthSelected) < currentMonth)) {
            message = "Para poder subir información de meses pasados, se necesita la aprobación por correo del area de BI"
        }else {
            message = "Para poder subir información de meses posteriores, se necesita la aprobación por correo del area de BI"
        }
        setFileUrl([])
        setFileNameTag()
        alertDateInvalid(message)
    }

    const validateArchivoPlanoDistributors = async (file) => {
        const res = await dispatch(extraAction(file))
        return res
    }

    return(
        <>
        <Card
            className={`Card-File ${loading ? 'Card-Loading' : errorFile ? 'Card-Error' : ''}`}
            cover={
                sendDataFile
                ?   loading
                    ?   <div style={{backgroundColor: 'white', textAlign: 'center'}}>
                            <img src={CargandoCaminata} className="Image-Loading-Card-File" />
                        </div>
                    :   errorFile
                        ?   <div className="Container-Error-Card-File">
                                <div className="Container-Error-Body-Card-File">
                                    <ExclamationOutlined 
                                        style={{
                                            color: "white",
                                            fontSize: "80px"
                                        }}
                                    />
                                </div>
                            </div>
                        :   <img src={Saltando} className="Image-Card-File" />
                :   <img src={Cohete} className="Image-Card-File" />
            }
        >
            {
                !loading
                ?   <>
                    {
                        errorFile
                        ?   <>
                            <div>
                                <div className="Title-Error">!Algo salió mal!</div>
                                <div className="Text-Error">Encontramos un error en el archivo</div>
                            </div>
                            </>
                        :   <>
                            <Meta
                                avatar={
                                    titleCard == 'Archivo Plano SO' || titleCard == 'Añadir no HML'
                                    ? <>
                                        <div 
                                            onClick={() => setShowDatePicker(!showDatePicker)} 
                                            className="Container-Icon-Card-File"
                                        >
                                            <DatePicker
                                                suffixIcon={<PlusOutlined className="Image-Icon-Card-File" />}
                                                onChange={(_, date) => {
                                                    validateDate(date)
                                                }}
                                                picker="month" 
                                                allowClear={false}
                                                open={showDatePicker} bordered={false}
                                                value={''}
                                            />
                                        </div>
                                        <input 
                                            type="file" 
                                            ref={RefFile} 
                                            style={{display: 'none'}} 
                                            onChange={async (e) => {
                                                setShowDatePicker(false)
                                                imageFileUpload(e.target.files[0], e)
                                                // if(titleCard == "Archivo Plano SO"){
                                                //     setDisabledDistributor(true)
                                                //     const res = await validateArchivoPlanoDistributors([e.target.files[0]])
                                                //     if(res) setDisabledDistributor(false)
                                                // }
                                                e.target.value = null
                                            }} 
                                        />
                                    </>
                                    : <>
                                        <div 
                                            onClick={() => {
                                                if(titleCard == 'Sell Out'){
                                                    const date_limit = uploaddatarestriction.find(upl => upl.tcanombre == titleCard)
                                                    const date_now = moment().date()
                                                    if(date_now > date_limit.tcadiarestriccion){
                                                        loadRestriction(date_limit.tcadiarestriccion)
                                                    }else{
                                                        RefFile.current.click()
                                                    }
                                                }else{
                                                    RefFile.current.click()
                                                }
                                            }}  
                                            className="Container-Icon-Card-File">
                                            <PlusOutlined className="Image-Icon-Card-File" />
                                        </div>
                                        <input 
                                            type="file" 
                                            ref={RefFile} 
                                            style={{display: 'none'}} 
                                            onChange={(e) => {
                                                imageFileUpload(e.target.files[0], e)
                                                e.target.value = null
                                            }} 
                                        />
                                    </>
                                }
                                title={titleCard}
                                description={textCard}
                            />
                            {
                                fileNameTag
                                &&  <div className="Container-Tag-Card-File">
                                        <span className="Linear-Tag-Card-File"></span>
                                        {
                                            fileNameTag
                                            &&  <Tag 
                                                    className="Tag-Card-File"
                                                    // closable
                                                    // onClose={deleteFileUpload}
                                                >
                                                    {fileNameTag}
                                                </Tag>
                                        }
                                    </div>
                            }
                            </>
                    }
                    <Button 
                        type="primary" 
                        className="Button-Card-File"
                        onClick={() => {
                            if(titleCard == 'Archivo Plano SO' && disabledDistributor){
                                setDisabledDistributor(false)
                                setFileNameTag(false)
                                setFileUrl([])
                            }else{
                                sendFile()
                            }
                        }}
                    >
                        {
                            titleCard == 'Archivo Plano SO' && disabledDistributor
                            ?   "Subir Otro"
                            :   !errorFile
                                    ?   "Enviar"
                                    :   "Subir Otro"
                        }
                    </Button>
                    </>
                :   <>
                    <div className="Title-Loading">Cargando</div>
                    <div className="Text-Loading">Cargando 1 archivo</div>
                    </>
            }
        </Card>
        </>
    )
}

export default CardUploadFile